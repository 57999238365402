<template>
    <div class="fullscreenObjOuther" v-if="imgData !== null">
        <img
            :src="imgData.img2_p"
            class="fullscreenImg"
        >
        <h3 class="fullscreenTitle" v-if="showInfo">
            <input type="text" class="titleInput" placeholder="Gib dem Bild einen Titel" v-model="imageTitle">
            <div class="info">
                <p class="infoSize" v-if="imgData.info.date || imgData.info.position.replace(/[^a-zA-Z0-9]/gm, '')">
                    {{capitalizeFirstLetter(imgData.info.date)}} - {{capitalizeFirstLetter(imgData.info.position)}}
                </p>
                <textarea class="description infoSize" placeholder="Schreibe eine Beschreibung zu diesem Bild" v-model="description"></textarea>
            </div>
        </h3>
        <button class="closeBut" v-on:click="reset">
            <span class="material-icons-outlined">
            close
            </span>
        </button>
        <button class="delBut" v-on:click="deleteImg">
            <span class="material-icons-round">
            delete_forever
            </span>
        </button>
        <button class="nextBut" v-on:click="nav(true)">
            <span class="material-icons-round">
            navigate_next
            </span>
        </button>
        <button class="prevBut" v-on:click="nav(false)">
            <span class="material-icons-round">
            navigate_before
            </span>
        </button>
        <div class="downloadButtons">
            <a :href="imgData.img2_p" target="_blank" rel="noopener noreferrer" :download="'1080p-'+imgData.info.title">
                <button>
                    <span class="material-icons-round">
                    hd
                    </span>
                </button>
            </a>
            <a :href="imgData.img3_p" target="_blank" rel="noopener noreferrer" :download="'4k-'+imgData.info.title">
                <button>
                    <span class="material-icons-round">
                    4k
                    </span>
                </button>
            </a>
            <a :href="imgData.img0_p" target="_blank" rel="noopener noreferrer" :download="'RAW-'+imgData.info.title">
                <button>
                    <span class="material-icons-round">
                    raw_on
                    </span>
                </button>
            </a>
            <button
                v-on:click="(showInfo)?showInfo=false:showInfo=true"
                v-if="!showInfo">
                <span class="material-icons-round">
                info
                </span>
            </button>
            <button
            v-on:click="(showInfo)?showInfo=false:showInfo=true"
            v-if="showInfo"
            style="outline: 2px solid orange;">
                <span class="material-icons-round">
                info
                </span>
            </button>
        </div>
    </div>
</template>

<style scoped>
    .fullscreenObjOuther{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .95);
        z-index: 200;
        transform: .2s ease;
    }
    .fullscreenImg{
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .closeBut{
        position: absolute;
        z-index: 201;
        right: 10px;
        top: 10px;
    }
    .delBut{
        position: absolute;
        z-index: 201;
        left: 10px;
        bottom: 10px;
    }
    .nextBut{
        position: absolute;
        z-index: 201;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .prevBut{
        position: absolute;
        z-index: 201;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .fullscreenTitle{
        position: absolute;
        z-index: 201;
        left: 10px;
        top: 10px;
        /* text-shadow: 0px 0px 10px rgba(0, 0, 0, .75); */
        padding: 10px;
        margin: 10px;
        border-radius: 5px;
        background-color: #fff;
        color: #000;
    }
    .downloadButtons{
        position: absolute;
        z-index: 201;
        right: 10px;
        bottom: 10px;
    }
    .downloadButtons button{
        margin: 10px;
    }
    button span{
        margin: 0px;
        text-decoration: none;
    }
    .info{
        position: absolute;
        bottom: 0px;
        transform: translateY(100%);
        left: 0px;
        width: 400px;
        font-size: 11pt;
        font-weight: 400;
        padding: 30px 0px;
    }
    .info > p{
        padding: 10px;
        border-radius: 5px;
        color: #000;
        background-color: #fff;
    }
    .description{
        padding: 10px;
        border-radius: 5px;
        color: #000;
        background-color: #fff;
        margin: 30px 0px;
        border: 0px;
        resize: vertical;
        max-height: 70vh;
    }

    .titleInput{
        width: 100%;
        background-color: transparent;
        border: 0px;
        color: #000;
    }

    .infoSize{
        width: 300px;
        max-width: 70%;
    }
</style>

<script>
import axios from 'axios'

export default {
    data(){
        return {
            showInfo: false,
            description: null,
            imageTitle: null
        }
    },
    created(){
        this.resized();

        window.addEventListener('resize', this.resized);
        document.body.addEventListener('scroll', this.resized);
    },
    beforeUnmount(){
        window.removeEventListener('resize', this.resized);
        document.body.removeEventListener('scroll', this.resized);
    },
    methods: {
        resized(){
            let el = document.body;
            let top = el.scrollTop;
            if(this.imgData !== null){
                this.description = this.imgData.info.info;
                this.imageTitle = this.imgData.info.title;
                document.querySelector('.fullscreenObjOuther').style.top = `${top}px`;
            }
        },
        reset(){
            this.$emit('resetImgData');
        },
        reload(){
            this.$emit('reloadImgData');
            this.reset();
        },
        deleteImg(){
            if(confirm('Bist du dir sicher, dass dieses Bild unwiederruflich gelöscht werden soll?')){
                let formData = new FormData;
                console.log(this.imgData);

                formData.append('id', this.imgData.info.id);

                axios.post('/api/v1/album/delete/img/', formData)
                .then(response => {
                    if(response.data.success === true){
                        this.reload();
                    }else{
                        if(response.data.error){
                            alert(response.data.error);
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
        },
        nav(next){
            if(typeof this.imgData.currentPos == 'undefined'){
                console.log('data is undefined')
                return;
            }
            let nav = (next===true)?parseInt(this.imgData.currentPos)+1:parseInt(this.imgData.currentPos)-1;
            if(document.querySelectorAll('[get-count]').length - 5 > this.imgData.currentPos){
                document.body.scrollTo(0,document.body.scrollHeight);
            }

            console.log('new emit: '+nav)
            this.$emit('openImg', nav)
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    },
    props: [
        'imgData'
    ],
    watch: {
        imgData: function() {
          setTimeout(()=>this.resized(), 100);
        },
        imageTitle: function() {
            if(this.imageTitle == null || this.imgData == null || this.imageTitle == this.imgData.info.title){
                return;
            }
            console.log('update title');
            let formData = new FormData;
            formData.append('id', this.imgData.info.id);
            formData.append('newName', this.imageTitle);

            axios.post('/api/v1/album/rename/img/', formData)
            .then(response => {
                if(response.data.success === true){
                    //ok
                }else{
                    
                    if(response.data.error){
                        alert(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        description: function() {
            if(this.description == null || this.imgData == null || this.description == this.imgData.info.info){
                return;
            }
            console.log('update title');
            let formData = new FormData;
            formData.append('id', this.imgData.info.id);
            formData.append('newName', this.description);

            axios.post('/api/v1/album/rename/img/desc.php', formData)
            .then(response => {
                if(response.data.success === true){
                    //ok
                }else{
                    if(response.data.error){
                        alert(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
}
</script>